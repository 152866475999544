import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import * as sponsorshipRequestsStyles from "./sponsorship-requests.module.scss"

const Georgia4hCause = ({ pageContext, location }) => {
  return (
    <>
      <Layout>
        <Seo title="Georgia Boot x 4H Retailer Cause Marketing Program" />
        <InnerHeader
          pageTitle="Georgia Boot x 4H"
          pageContext={pageContext}
          location={location}
        />
        <div
          className={`inner-container-alt inner-y-padding ${sponsorshipRequestsStyles.contentWrapper}`}
        >
          <h2 style={{ maxWidth: `900px` }}>
            Georgia Boot x 4H Retailer Cause Marketing Program
          </h2>

          <div>
            {/* form start */}
            <form
              name="georgia-boot-4h-cause-marketing-program"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              enctype="multipart/form-data"
              action="/form-confirmation/georgia-boot-4h-cause-marketing-sign-up"
            >
              <small>
                <strong>
                  Note: Each request submitted does not guarantee a response. We
                  will contact you if there is interest in{" "}
                  <span
                    style={{
                      whiteSpace: `nowrap`,
                    }}
                  >
                    your submission
                  </span>
                  .
                  <br /> <br />
                </strong>
              </small>
              <small>
                <span
                  className="text-danger"
                  style={{ fontSize: `1.1rem`, fontWeight: `bold` }}
                >
                  *
                </span>{" "}
                indicates required field
              </small>
              <input
                type="hidden"
                name="form-name"
                value="georgia-boot-4h-cause-marketing-program"
              />
              {/* account number */}
              <div className="form-group">
                <label htmlFor="account-number">Account Number</label>
                <input
                  type="number"
                  name="account-number"
                  id="account-number"
                  className="form-control"
                  placeholder="Account Number"
                />
              </div>
              {/* account name */}
              <div className="form-group">
                <label htmlFor="account-name required">Account Name</label>
                <input
                  type="text"
                  name="account-name"
                  id="account-name"
                  className="form-control"
                  placeholder="Account Name"
                  required
                />
              </div>
              {/* contact name */}
              <div className="form-group">
                <label htmlFor="contact-name required">Contact Name</label>
                <input
                  type="text"
                  name="contact-name"
                  id="contact-name"
                  className="form-control"
                  placeholder="Contact Name"
                  required
                />
              </div>
              {/* email */}

              <div className="form-group">
                <label htmlFor="contact-email required">Contact Email</label>
                <input
                  type="email"
                  name="contact-email"
                  id="contact-email"
                  className="form-control"
                  placeholder="Contact Email"
                  required
                />
              </div>
              {/* phone */}
              <div className="form-group">
                <label htmlFor="contact-phone required">Contact Phone</label>
                <input
                  type="tel"
                  name="contact-phone"
                  id="contact-phone"
                  className="form-control"
                  placeholder="Contact Phone"
                  required
                />
              </div>
              {/* month */}
              <div className="form-group">
                <label htmlFor="desired-month required">
                  Desired Month to Run Program{" "}
                </label>
                <br /><small>
                  Please allow three to four weeks to receive promotion materials prior to the desired month.
                </small>
                <select
                  name="desired-month"
                  id="desired-month"
                  className="form-control"
                  required
                >
                  <option value="">Select One</option>
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </select>
              </div>

              {/* number of stores that will participate optional */}

              <div className="form-group">
                <label htmlFor="number-of-stores">
                  Number of Stores that will Participate
                </label>
                <input
                  type="number"
                  name="number-of-stores"
                  id="number-of-stores"
                  className="form-control"
                  placeholder="Number of Stores that will Participate"
                />
              </div>

              {/* submit button */}
              <div>
                <input className="CTAblack" type="submit" value="Submit" />
              </div>
            </form>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Georgia4hCause
